<template>
  <div>
    <!-- <pre> {{ $props }} </pre> -->
    <!-- <pre> {{ $data }} </pre> -->

    <FormulateForm ref="proyeccionImporteForm" @submit="submit" #default="{ hasErrors, isLoading }">
      <!-- Proyectos -->
      <FormulateInput
        name="proyecto"
        v-model="formValues.proyecto_id"
        :disabled="isLoading"
        validation="bail|required"
        error-behavior="live"
        :validation-messages="{
          required: 'El proyecto es requerido'
        }"
      >
        <template #element>
          <x-form-group title="Proyecto" required>
            <check-authorization
              :requiresAuthorizations="['listar proyectos']"
              :show-alert="false"
              no-slots
              #default="{ authorized, message }"
            >
              <span v-b-tooltip.top :title="authorized ? '' : message">
                <x-select
                  ref="xselectproyectos"
                  v-model="formValues.proyecto_id"
                  :dispatchPath="null"
                  getterListPath="proyectoModule/getAllProyectos"
                  :formatListWith="['id', 'nombre']"
                  no-validate
                  no-prepend
                >
                  <b-form-select-option :value="null" disabled>
                    -- Selecciona un proyecto --
                  </b-form-select-option>
                </x-select>
              </span>
            </check-authorization>
          </x-form-group>
        </template>
      </FormulateInput>

      <!-- Unidades administrativas -->
      <FormulateInput
        name="unidad_administrativa"
        v-model="formValues.unidad_administrativa_id"
        :disabled="isLoading"
        validation="bail|required"
        error-behavior="live"
        :validation-messages="{
          required: 'La unidad administrativa es requerida'
        }"
      >
        <template #element>
          <x-form-group title="Unidad administrativa" required>
            <check-authorization
              :requiresAuthorizations="['listar unidades administrativas']"
              :show-alert="false"
              no-slots
              #default="{ authorized, message }"
            >
              <span v-b-tooltip.top :title="authorized ? '' : message">
                <x-select
                  ref="xselectunidades"
                  v-model="formValues.unidad_administrativa_id"
                  :dispatchPath="null"
                  getterListPath="administrativeUnitModule/getAdministrativeUnits"
                  :formatListWith="['id', 'nombre']"
                  no-validate
                  no-prepend
                >
                  <b-form-select-option :value="null" disabled>
                    -- Selecciona una unidad administrativa --
                  </b-form-select-option>
                </x-select>
              </span>
            </check-authorization>
          </x-form-group>
        </template>
      </FormulateInput>

      <!-- Mes -->
      <FormulateInput
        name="mes"
        v-model="formValues.mes"
        :disabled="isLoading"
        validation="bail|required"
        error-behavior="live"
        :validation-messages="{
          required: 'El mes es requerido'
        }"
      >
        <template #element>
          <x-form-group title="Selecciona un mes" required>
            <x-select
              v-model="formValues.mes"
              getterListPath="UIModule/getMonths"
              :formatListWith="['id', 'value']"
              no-validate
              no-prepend
            >
              <b-form-select-option :value="null" disabled>
                -- Selecciona un mes --
              </b-form-select-option>
            </x-select>
          </x-form-group>
        </template>
      </FormulateInput>

      <!-- Importe -->
      <FormulateInput
        name="importe"
        v-model="formValues.importe"
        :disabled="isLoading"
        validation="bail|required"
        error-behavior="live"
        :validation-messages="{
          required: 'El importe es requerido'
        }"
      >
        <template #element>
          <x-form-group title="Importe" required>
            <b-row>
              <b-col>
                <b-form-input type="number" v-model.number="formValues.importe"></b-form-input>
              </b-col>
              <b-col>
                <b-form-input readonly :value="$formatNumber(formValues.importe)"></b-form-input>
              </b-col>
            </b-row>
          </x-form-group>
        </template>
      </FormulateInput>

      <!-- Botones para enviar formulario o cancelar modal -->
      <x-form-footer-buttons
        :disabled="hasErrors || isLoading"
        :loading="isLoading"
        :isEditing="false"
        :isCreating="true"
        @on-click-create="submit"
      >
        <template #cancel>
          <slot name="btn-cancel-place"></slot>
        </template>
      </x-form-footer-buttons>
    </FormulateForm>
  </div>
</template>

<script>
const formValues = () => ({
  proyecto_id: null,
  unidad_administrativa_id: null,
  mes: '',
  importe: 0
})

export default {
  name: 'ProyeccionImporteForm',

  props: {
    periodo_fiscal_id: {
      type: Number,
      required: true
    },

    tipo: {
      type: String,
      required: true
    },

    num_ampliacion: {
      type: Number,
      required: true
    },

    fuente_financiamiento_id: {
      type: Number,
      required: true
    },

    prestacion_id: {
      type: Number,
      required: true
    },

    mes: {
      type: Number,
      default: 0
    }
  },

  created () {
    this.formValues.mes = this.mes
  },

  mounted () {
    this.init()
  },

  data () {
    return {
      formValues: formValues()
    }
  },

  methods: {
    init () {
      this.getProyectos()
      this.getUnidadesAdministrativas()
    },

    async getProyectos () {
      try {
        this.$refs.xselectproyectos.loading = true
        await this.$store.dispatch('proyectoModule/getProyectos', this.periodo_fiscal_id)
        this.$refs.xselectproyectos.loading = false
      } catch (error) {}
    },

    async getUnidadesAdministrativas () {
      try {
        this.$refs.xselectunidades.loading = true
        await this.$store.dispatch(
          'administrativeUnitModule/getAdministrativeUnits',
          this.periodo_fiscal_id
        )
        this.$refs.xselectunidades.loading = false
      } catch (error) {}
    },

    async submit () {
      this.formValues = {
        periodo_fiscal_id: this.periodo_fiscal_id,
        tipo: this.tipo,
        num_ampliacion: this.num_ampliacion,
        fuente_financiamiento_id: this.fuente_financiamiento_id,
        prestacion_id: this.prestacion_id,
        ...this.formValues
      }

      const getval = await this.$store.dispatch('planeacionModule/crearImporte', {
        ...this.formValues
      })

      this.$notify(getval, 'Creación de importe')

      if (getval.error) return

      this.reset()
      this.onCreated()
    },

    onCreated () {
      this.$emit('on-created')
    },

    reset () {
      this.formValues = formValues()
    }
  }
}
</script>
