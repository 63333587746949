<template>
  <check-authorization :requiresAuthorizations="['consultar analisis presupuesto']">
    <!-- Modal para la exportación de los archivos -->
    <analisis-export-file-modal :model="exportReports" @on-submitted="resetExportInput" />

    <!-- Notificacion de alerta -->
    <portal-target name="notificationAlertAnalisisTable" />

    <!-- Card que muestra la tabla y la información del análisis -->
    <b-card no-body class="card card-header-actions mb-3">
      <b-card-header>
        <div v-if="tableLoading" class="d-flex align-items-center">
          <b-spinner class="mr-4" variant="black" label="Cargando información..." />
          Actualizando información...
        </div>
        <div v-else>
          Análisis de presupuesto
        </div>

        <div class="dropdown no-caret">
          <button
            class="btn btn-transparent-dark btn-icon dropdown-toggle"
            type="button"
            data-toggle="dropdown"
          >
            <i data-feather="more-vertical"></i>
          </button>
          <div class="dropdown-menu dropdown-menu-right animated--fade-in-up">
            <a class="dropdown-item my-cursor" @click="openModalForExport('proyectos')">
              <i data-feather="file" class="mr-1"></i>
              Exportar proyectos
            </a>
            <a class="dropdown-item my-cursor" @click="openModalForExport('unidades')">
              <i data-feather="file" class="mr-1"></i>
              Exportar unidades
            </a>
            <a class="dropdown-item my-cursor" @click="openModalForExport('cuadernos')">
              <i data-feather="file" class="mr-1"></i>
              Exportar cuadernos de trabajo
            </a>
            <a class="dropdown-item my-cursor" @click="openModalForExport('layout')">
              <i data-feather="file" class="mr-1"></i>
              Exportar layout SAAGC.NET
            </a>
          </div>
        </div>
      </b-card-header>
      <!-- TOTALES -->
      <b-card-header class="d-flex justify-content-end">

        <check-authorization
          :requiresAuthorizations="['autorizar analisis presupuesto']"
          :show-alert="false"
          no-slots
          #default="{ authorized, message }"
        >
          <span v-b-tooltip.top :title="!authorized ? message : ''">
            <b-button
              @click="autorizar"
              variant="danger"
              size="sm"
              :disabled="!authorized || !tableLoaded || autorizacion.loading"
            >
              {{ autorizacion.loading ? 'Espere...' : 'Autorizar' }}
            </b-button>
          </span>
        </check-authorization>
      </b-card-header>

      <b-card-body class="p-1 px-0">
        <div class="mr-auto">
          <!--x-select-periodo-fiscal class="mb-3" @input="onChangePeriodoFiscal" /-->
          <FormulateForm
            ref="importacionPlaneacionForm"
            @submit="submit"
            #default="{ hasErrors, isLoading }"
          >
            <!-- Periodo fiscal -->
            <FormulateInput
              name="periodo_fiscal"
              v-model="periodo_fiscal_id"
              :disabled="isLoading"
              validation="bail|required"
              error-behavior="live"
              :validation-messages="{
                  required: 'El periodo fiscal es requerido'
                }"
            >
              <template #element>
                <x-form-group title="Periodo fiscal" required>
                  <check-authorization
                    :requiresAuthorizations="['listar periodos fiscales']"
                    :show-notify="false"
                    no-slots
                    #default="{ authorized, message }"
                  >
                      <span v-b-tooltip.top :title="!authorized ? message : ''">
                        <x-select
                          v-model="periodo_fiscal_id"
                          dispatchPath="fiscalPeriodsModule/getFiscalPeriods"
                          getterListPath="fiscalPeriodsModule/getAllFiscalPeriods"
                          :formatListWith="['id', 'nombre']"
                          :disabled="!authorized || isLoading"
                          no-validate
                          no-prepend
                          defaultText="-- Selecciona un periodo fiscal --"
                        ></x-select>
                      </span>
                  </check-authorization>
                </x-form-group>
              </template>
            </FormulateInput>

            <!-- Especificación de tipo de planificación: planeación o ampliación -->
            <FormulateInput
              name="tipo"
              title="Tipo"
              type="select"
              :options="{Planeacion: 'Planeación', Ampliacion: 'Ampliación'}"
              v-model="tipo"
              :disabled="!enableTipo"
              validation="bail|required"
              error-behavior="live"
              :validation-messages="{
                  required: 'Debe especificar el tipo'
                }"></FormulateInput>

            <!-- En caso de estar haciendo una ampliación, especificar el número -->
            <FormulateInput
              name="num_ampliacion"
              title="Número de ampliación"
              type="select"
              :options="numAmpliacionList"
              v-model="num_ampliacion"
              :disabled="!enableList"
              error-behavior="live"
              ></FormulateInput>
          </FormulateForm>

          <div v-if="Boolean(totales)" class="d-flex justify-content-around align-items-center">
            <b-table-simple>
              <b-thead>
                <b-tr class="text-right">
                  <b-th></b-th>
                  <b-th>Global</b-th>
                  <b-th>Estatal</b-th>
                  <b-th>Ingresos Propios</b-th>
                </b-tr>
              </b-thead>
              <b-tbody class="text-right">
                <b-tr>
                  <b-th class="text-left">Total</b-th>
                  <b-td>
                    <b-badge style="font-size: 0.83rem" variant="black" class="mr-1">
                      {{ $formatNumber(totales.total_global) }}
                    </b-badge>
                  </b-td>
                  <b-td>
                    <b-badge style="font-size: 0.83rem" variant="primary" class="mr-1">
                      {{ $formatNumber(totales.total_estatal) }}
                    </b-badge>
                  </b-td>
                  <b-td>
                    <b-badge style="font-size: 0.83rem" variant="success" class="mr-1">
                      {{ $formatNumber(totales.total_ingresos_propios) }}
                    </b-badge>
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-th class="text-left">Techo Financiero</b-th>
                  <b-td></b-td>
                  <b-td>
                    <b-badge style="font-size: 0.83rem" variant="primary" class="mr-1">
                      {{ $formatNumber(totales.techo_estatal) }}
                    </b-badge>
                  </b-td>
                  <b-td>
                    <b-badge style="font-size: 0.83rem" variant="success" class="mr-1">
                      {{ $formatNumber(totales.techo_ingresos_propios) }}
                    </b-badge>
                  </b-td>
                </b-tr>
                <b-tr>
                  <b-th class="text-left">Diferencia</b-th>
                  <b-td></b-td>
                  <b-td>
                    <b-badge v-if="totales.diferencia_estatal == 0" style="font-size: 0.83rem" variant="black" class="mr-1">
                      {{ $formatNumber(totales.diferencia_estatal) }}
                    </b-badge>
                    <b-badge v-else style="font-size: 0.83rem" variant="danger" class="mr-1">
                      {{ $formatNumber(totales.diferencia_estatal) }}
                    </b-badge>
                  </b-td>
                  <b-td>
                    <b-badge v-if="totales.diferencia_ingresos_propios == 0" style="font-size: 0.83rem" variant="black" class="mr-1">
                      {{ $formatNumber(totales.diferencia_ingresos_propios) }}
                    </b-badge>
                    <b-badge v-else style="font-size: 0.83rem" variant="danger" class="mr-1">
                      {{ $formatNumber(totales.diferencia_ingresos_propios) }}
                    </b-badge>
                  </b-td>
                </b-tr>
              </b-tbody>
            </b-table-simple>
            <!-- FIX: iMPLEMENTAR FUNCIONAMIENTO -->
            <!-- <b-badge style="font-size: 0.83rem" variant="danger" class="mr-1">
              Excedente: {{ $formatNumber(totales.total_ingresos_propios) }}
            </b-badge> -->
          </div>
        </div>

        <alert-with-errors
          title="Autorización de análisis de presupuesto"
          :error="autorizacion.error"
          :message="autorizacion.message"
          :errors="autorizacion.data.errors"
        />

        <b-table
          :fields="getFields"
          :items="getItems"
          responsive
          bordered
          hover
          striped
          style="min-height: 50vh; max-height: 500px;"
          sticky-header
          head-variant="light"
          head-row-variant="light"
          show-empty
          small
        >
          <template #cell(Partida)="data">
            <div class="d-flex align-items-center my-auto text-center" style="min-height: 80px">
              <b-badge class="w-100">{{ data.value }}</b-badge>
            </div>
          </template>

          <template #cell(Concepto)="data">
            <div
              class="d-flex align-items-center text-center overflow-auto"
              style="min-height: 80px"
            >
              <span class="text-black text-center w-100" style="font-size: 0.7rem">
                {{ data.value }}
              </span>
            </div>
          </template>

          <template #cell(global)="data">
            <div class="d-flex align-items-center text-center" style="min-height: 80px">
              <b-badge variant="black">{{ $formatNumber(data.value) }}</b-badge>
            </div>
          </template>

          <template #cell()="data">
            <!-- Formulario para hacer la separación de importes -->
            <analisis-cell :row="data" v-on:closed="submit"></analisis-cell>
          </template>
        </b-table>
      </b-card-body>
    </b-card>
  </check-authorization>
</template>

<script>
import { EventBus } from '@/EventBus'
import AnalisisCell from './AnalisisCell'
import AnalisisExportFileModal from '@/components/Planeacion/AnalisisExportFileModal'
import planeacionMixin from '@/mixins/planeacionMixin'
import AlertWithErrors from '@/components/Shared/AlertWithErrors'

const autorizacion = () => ({
  error: false,
  loading: false,
  message: '',
  data: {
    errors: {}
  }
})

export default {
  name: 'AnalisisTable',

  mixins: [planeacionMixin],

  components: {
    AnalisisCell,
    AlertWithErrors,
    AnalisisExportFileModal
  },

  data () {
    return {
      fields: [],
      items: [],
      response: [],
      totales: [],

      tableLoading: false,
      tableLoaded: false,

      periodo_fiscal_id: null,
      tipo: null,
      num_ampliacion: null,

      numAmpliacionList: [],

      autorizacion: autorizacion(),

      exportReports: {
        name: '',
        loading: false,
        fuente_financiamiento_id: null,
        periodo_fiscal_id: null,
        tipo: null,
        num_ampliacion: null
      }
    }
  },

  computed: {
    getItems () {
      return this.response ? this.response.slice(1, this.response.length) : []
    },

    getFields () {
      return this.response ? this.response[0] : []
    }
  },

  watch: {
    periodo_fiscal_id: {
      inmediate: true,
      async handler (value) {
        if (value) {
          this.enableTipo = true
        }
        if (this.tipo === 'Ampliacion') {
          const retval = await this.$store.dispatch('planeacionModule/getNumAmpliacionList', { periodo_fiscal_id: this.periodo_fiscal_id })
          this.numAmpliacionList = retval.data.list_num_ampliacion.map(item => item.num_ampliacion)
          this.enableList = true
        } else {
          this.onChangePeriodoFiscal(value)
          this.enableList = false
        }
      }
    },
    tipo: {
      inmediate: true,
      async handler (value) {
        if (value === 'Ampliacion') {
          const retval = await this.$store.dispatch('planeacionModule/getNumAmpliacionList', { periodo_fiscal_id: this.periodo_fiscal_id })
          this.numAmpliacionList = retval.data.list_num_ampliacion.map(item => item.num_ampliacion)
          this.enableList = true
        } else {
          this.num_ampliacion = 1
          this.onChangePeriodoFiscal(this.periodo_fiscal_id)
          this.enableList = false
        }
      }
    },
    num_ampliacion: {
      inmediate: true,
      async handler (value) {
        this.onChangePeriodoFiscal(this.periodo_fiscal_id)
      }
    }
  },

  methods: {
    async submit () {
      if ((this.tipo !== 'Planeacion') && (this.tipo !== 'Ampliacion')) {
        return
      }
      if ((this.tipo === 'Ampliacion') && (this.num_ampliacion === 0)) {
        return
      }
      this.tableLoading = true
      this.response = []
      this.totales = []

      // Ahora se utilizará el período fiscal del selector
      const getval = await this.$store.dispatch('planeacionModule/getAnalisisBy', {
        periodo_fiscal_id: this.periodo_fiscal_id,
        tipo: this.tipo,
        num_ampliacion: this.num_ampliacion
      })
      this.tableLoading = false
      this.tableLoaded = true

      this.$notify(getval, 'Análisis')

      this.$alert(getval, 'Obtener tabla de análisis', 'notificationAlertAnalisisTable', {
        closeOnTimeout: false
      })

      if (getval.error) return

      const { importes, totales } = getval.data

      this.response = importes
      this.totales = totales
    },

    async onChangePeriodoFiscal (periodo_fiscal_id) {
      if ((this.tipo === 'Planeacion') || ((this.tipo === 'Ampliacion') && (this.num_ampliacion > 0))) {
        this.tableLoading = true
        this.tableLoaded = false
        this.periodo_fiscal_id = periodo_fiscal_id
        this.submit()

        EventBus.$on('on-change-analisis-desglose-partida-importe', this.submit)
      }
    },

    async autorizar () {
      this.autorizacion = autorizacion()

      this.autorizacion.loading = true
      const getval = await this.$store.dispatch('planeacionModule/autorizar')
      this.autorizacion.loading = false

      this.$notify(getval, 'Autorización de análisis presupuestal')

      if (getval.error) this.autorizacion = getval
    },

    openModalForExport (name) {
      this.exportReports.name = name
      this.exportReports.periodo_fiscal_id = this.periodo_fiscal_id
      this.exportReports.tipo = this.tipo
      this.exportReports.num_ampliacion = this.num_ampliacion

      this.$bvModal.show('modalAnalisisExportReports')
    },

    resetExportInput () {
      this.exportReports.periodo_fiscal_id = null
      this.exportReports.name = ''
      this.exportReports.fuente_financiamiento_id = null
      this.exportReports.tipo = null
      this.exportReports.num_ampliacion = null
      this.$bvModal.hide('modalAnalisisExportReports')
    }
  }
}
</script>
