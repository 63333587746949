<template>
  <b-modal
    id="modalAnalisisExportReports"
    centered
    no-close-on-backdrop
    hide-header-close
    hide-footer
    @change="getFuentesFinanciamiento"
  >
    <template #modal-title>Exportar {{ model.name }}</template>

    <template #default="{cancel}">
      <FormulateForm ref="exportForm" #default="{ hasErrors, isLoading }" @submit="submit">
        <!-- Seleccion de fuente de financiamiento -->
        <FormulateInput
          name="fuente_financiamiento"
          v-model="model.fuente_financiamiento_id"
          :disabled="isLoading"
          validation="bail|required"
          error-behavior="live"
          :validation-messages="{
            required: 'La fuente de financiamiento es requerida'
          }"
        >
          <template #element>
            <x-form-group title="Fuente de financiamiento" required>
              <x-select
                ref="xselectfuentesfinanciamiento"
                v-model="model.fuente_financiamiento_id"
                :dispatchPath="null"
                getterListPath="fuenteFinanciamientoModule/getList"
                :formatListWith="['id', 'nombre']"
                :disabled="isLoading || xSelectDisabled"
                no-validate
                no-prepend
                defaultText="-- Selecciona una fuente de financiamiento --"
              />
            </x-form-group>
          </template>
        </FormulateInput>

        <!-- Periodo fiscal -->
        <FormulateInput
          name="periodo_fiscal"
          v-model="model.periodo_fiscal_id"
          :disabled="isLoading"
          validation="bail|required"
          error-behavior="live"
          :validation-messages="{
            required: 'El periodo fiscal es requerido'
          }"
        >
          <template #element>
            <x-form-group title="Periodo fiscal" required>
              <x-select
                v-model="model.periodo_fiscal_id"
                dispatchPath="fiscalPeriodsModule/getFiscalPeriods"
                getterListPath="fiscalPeriodsModule/getAllFiscalPeriods"
                :formatListWith="['id', 'nombre']"
                :disabled="isLoading"
                no-validate
                no-prepend
                defaultText="-- Selecciona un periodo fiscal --"
              ></x-select>
            </x-form-group>
          </template>
        </FormulateInput>

        <!-- Submit -->
        <x-form-footer-buttons
          :disabled="hasErrors || isLoading"
          :loading="isLoading"
          :isEditing="false"
          :isCreating="true"
          :btnCreateTitle="isLoading ? 'Obteniendo archivo...' : 'Enviar'"
          btnCreateType="submit"
        >
          <template #cancel>
            <b-button variant="black" size="sm" @click="cancel">Cancelar</b-button>
          </template>
        </x-form-footer-buttons>
      </FormulateForm>
    </template>
  </b-modal>
</template>

<script>
export default {
  name: 'AnalisisExportFileModal',

  props: {
    model: {
      type: Object,
      required: true
    }
  },

  data () {
    return {
      xSelectDisabled: true
    }
  },

  methods: {
    async getFuentesFinanciamiento () {
      this.xSelectDisabled = true

      // const periodo = await this.$getPeriodoFiscalActivo()

      const { error, message } = await this.$store.dispatch(
        'fuenteFinanciamientoModule/getAll',
        this.model.periodo_fiscal_id
      )
      this.xSelectDisabled = false

      if (error) this.$notify({ error, message }, 'Listado fuentes de financiamiento')
    },

    async submit () {
      this.model.loading = true
      const getval = await this.$store.dispatch('planeacionModule/exportReports', this.model)
      this.model.loading = true

      this.$onDownload(
        getval,
        `${this.model.name}-${this.model.periodo_fiscal_id}-${this.model.fuente_financiamiento_id}.xlsx`
      )

      if (getval.error === undefined) this.$emit('on-submitted', getval)
    }
  }
}
</script>
