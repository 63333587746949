<template>
  <b-row>
    <b-col cols="12" class="max--md center">
      <check-authorization :requiresAuthorizations="['importar salarios periodo']">
        <b-overlay :show="importacion.loading || proyeccion.loading">
          <template #overlay>
            <div class="text-center">
              <loading :message="processingMessage"></loading>
            </div>
          </template>

          <portal-target name="notificationAlertPlaneacionImportForm" />

          <b-card
            title="Importación"
            sub-title="Selecciona el archivo layout para su procesamiento."
            style="max-with: 400px"
          >
            <FormulateForm
              ref="importacionPlaneacionForm"
              @submit="submit"
              #default="{ hasErrors, isLoading }"
            >
              <!-- Archivo layout -->
              <FormulateInput
                name="file"
                v-model="formValues.file"
                :disabled="isLoading"
                validation="bail|required"
                error-behavior="live"
                :validation-messages="{
                  required: 'El archivo layout es requerido'
                }"
              >
                <template #element>
                  <x-form-group title="Archivo layout" description="(sólo archivos .xlsx)" required>
                    <b-form-file
                      v-model="formValues.file"
                      placeholder="Selecciona el archivo layout excel correspondiente..."
                      drop-placeholder="Arrastra el archivo layout excel correspondiente aquí..."
                    ></b-form-file>
                  </x-form-group>
                </template>
              </FormulateInput>

              <!-- Periodo fiscal -->
              <FormulateInput
                name="periodo_fiscal"
                v-model="formValues.periodo_fiscal_id"
                :disabled="isLoading"
                validation="bail|required"
                error-behavior="live"
                :validation-messages="{
                  required: 'El periodo fiscal es requerido'
                }"
              >
                <template #element>
                  <x-form-group title="Periodo fiscal" required>
                    <check-authorization
                      :requiresAuthorizations="['listar periodos fiscales']"
                      :show-notify="false"
                      no-slots
                      #default="{ authorized, message }"
                    >
                      <span v-b-tooltip.top :title="!authorized ? message : ''">
                        <x-select
                          v-model="formValues.periodo_fiscal_id"
                          dispatchPath="fiscalPeriodsModule/getFiscalPeriods"
                          getterListPath="fiscalPeriodsModule/getAllFiscalPeriods"
                          :formatListWith="['id', 'nombre']"
                          :disabled="!authorized || isLoading"
                          no-validate
                          no-prepend
                          defaultText="-- Selecciona un periodo fiscal --"
                        ></x-select>
                      </span>
                    </check-authorization>
                  </x-form-group>
                </template>
              </FormulateInput>

              <!-- Especificación de tipo de planificación: planeación o ampliación -->
              <FormulateInput
                name="tipo"
                title="Tipo"
                type="select"
                :options="{Planeacion: 'Planeación', Ampliacion: 'Ampliación'}"
                v-model="formValues.tipo"
                :disabled="!enableTipo"
                validation="bail|required"
                error-behavior="live"
                :validation-messages="{
                  required: 'Debe especificar el tipo'
                }"></FormulateInput>

              <!-- En caso de estar haciendo una ampliación, especificar el número -->
              <FormulateInput
                name="num_ampliacion"
                title="Número de ampliación"
                type="select"
                :options="numAmpliacionList"
                v-model="formValues.num_ampliacion"
                :disabled="!enableList"
                error-behavior="live"
                ></FormulateInput>

              <!-- Sobreescribir el archivo previamente cargado -->
              <b-form-group>
                <b-form-checkbox switch v-model="formValues.overwrite">
                  Sobreescribir el archivo previamente cargado
                </b-form-checkbox>
              </b-form-group>

              <!-- Guardar el archivo en memoria -->
              <b-form-group>
                <b-form-checkbox switch v-model="formValues.save_file">
                  Guardar el archivo en memoria
                </b-form-checkbox>
              </b-form-group>

              <!-- Opción para hacer el proceso para generar proyección después de la importación -->
              <b-form-group>
                <b-form-checkbox switch v-model="formValues.continuarGenerarProyeccion">
                  Generar proyección después de la importación
                </b-form-checkbox>
              </b-form-group>

              <!-- Botón para mostrar los errores de validación -->
              <b-form-group v-if="errors.length > 0">
                <b-button variant="danger" @click="$bvModal.show('modal-errors')">
                  Mostrar errores
                </b-button>
              </b-form-group>

              <!-- Submit -->
              <!--
          @NOTA: Los botones están de reversa.

                 El botón de cancelación es el botó para subir el archivo.

                 El botón para la creación genera la proyección.
         -->
              <b-form-group class="flex-row-reverse">
                <x-form-footer-buttons
                  :disabled="hasErrors || isLoading"
                  :loading="isLoading"
                  :isEditing="false"
                  btnCancelTitle="Subir archivo"
                  btnCancelVariant="success"
                  :btnCancelDisabled="hasErrors || isLoading"
                  @on-cancel="submit"
                  :isCreating="formValues.processed && !errors.length"
                  btnCreateTitle="Generar proyección"
                  btnCreateVariant="primary"
                  @on-create="submitGenerarProyeccion"
                />
              </b-form-group>
            </FormulateForm>
          </b-card>

          <!-- Modal para mostrar los errores de validación del layout -->
          <b-modal
            id="modal-errors"
            scrollable
            title="Se encontraron errores en el archivo layout"
            hide-footer
            no-close-on-backdrop
          >
            <b-card
              v-for="item in errors"
              :key="item.id"
              :header="'Fila número: ' + `${item.row + 1}`"
              no-body
              class="mb-3"
            >
              <b-list-group flush>
                <b-list-group-item variant="warning">
                  Columna: {{ item.attribute }}
                </b-list-group-item>
                <b-list-group-item v-for="desc in item.errors" :key="desc.id" variant="danger">
                  {{ desc }}
                </b-list-group-item>
              </b-list-group>
            </b-card>
          </b-modal>
        </b-overlay>
      </check-authorization>
    </b-col>
  </b-row>
</template>

<script>
import { mapState, mapMutations } from 'vuex'
import XSelect from '@/components/Shared/XSelect'
import planeacionMixin from '@/mixins/planeacionMixin'

const nullFormValues = () => ({
  file: null,
  periodo_fiscal_id: null,
  tipo: null,
  num_ampliacion: 1,

  overwrite: true,
  save_file: false,
  processed: false,
  continuarGenerarProyeccion: true
})

export default {
  name: 'PlaneacionImportacionForm',

  mixins: [planeacionMixin],

  components: {
    XSelect
  },

  data () {
    return {
      //
      // Archivo layout seleccionado
      file: null,

      processingMessage: null,

      formValues: nullFormValues(),

      numAmpliacionList: [],

      enableTipo: false,
      enableList: false,

      errors: []
    }
  },

  computed: {
    // ----------------------------------------------------------
    // PLANEACIÓN
    // ----------------------------------------------------------
    ...mapState('planeacionModule', ['importacion', 'proyeccion']),

    // Empleados dos propiedades calculadas para poder observar los cambios de las propiedades anidadas
    periodo_fiscal_id () {
      return this.formValues.periodo_fiscal_id
    },

    tipo () {
      return this.formValues.tipo
    }
  },

  watch: {
    periodo_fiscal_id: {
      inmediate: true,
      async handler (value) {
        if (value) {
          this.enableTipo = true
        }
        if (this.tipo === 'Ampliacion') {
          const retval = await this.$store.dispatch('planeacionModule/getNumAmpliacionList', { periodo_fiscal_id: this.periodo_fiscal_id })
          this.numAmpliacionList = retval.data.list_num_ampliacion.map(item => item.num_ampliacion === 0 ? 'Nueva ampliación' : item.num_ampliacion)
          this.enableList = true
        } else {
          this.enableList = false
        }
      }
    },
    tipo: {
      inmediate: true,
      async handler (value) {
        if (value === 'Ampliacion') {
          const retval = await this.$store.dispatch('planeacionModule/getNumAmpliacionList', { periodo_fiscal_id: this.periodo_fiscal_id })
          this.numAmpliacionList = retval.data.list_num_ampliacion.map(item => item.num_ampliacion === 0 ? 'Nueva ampliación' : item.num_ampliacion)
          this.enableList = true
        } else {
          this.enableList = false
        }
      }
    }
  },

  methods: {
    // ----------------------------------------------------------
    // PLANEACIÓN
    // ----------------------------------------------------------
    ...mapMutations('planeacionModule', ['setImportacion']),

    async submit () {
      this.processingMessage = 'Procesando archivo, por favor espere...'
      this.formValues.processed = false

      const getval = await this.$store.dispatch('planeacionModule/uploadLayout', this.formValues)

      //
      // Se notifica al usuario
      this.$notify(getval, 'Importación de layout')

      //
      // Termina si ha encontrado un error de validación en el archivo
      if (getval.error) {
        //
        // Asigna los errores para mostrarlos al usuario
        this.errors = getval.data

        //
        // Muestra el detalle de los errores de validación
        this.$bvModal.show('modal-errors')

        this.formValues.file = null

        return
      }

      this.formValues.processed = true

      if (this.formValues.continuarGenerarProyeccion) this.submitGenerarProyeccion()
    },

    async submitGenerarProyeccion () {
      this.processingMessage = 'Generando proyección, por favor espere...'

      const getval = await this.$store.dispatch(
        'planeacionModule/generarProyeccion',
        this.formValues
      )

      //
      // Notifica si ha encontrado un error al usuario
      this.$alert(getval, 'Generación de proyección', 'notificationAlertPlaneacionImportForm', {
        closeOnTimeout: false
      })

      //
      //  Fix: Verifica si hay algún error al momento de consultar
      console.log('getval :>> ', getval)
      this.errors = {}
    },

    resetForm () {
      try {
        document.activeElement.blur()
        this.formValues = nullFormValues()
        this.$refs.importacionPlaneacionForm.hideErrors()
      } catch (_) {}
    },

    prueba () {
      console.log('esta es una prueba')
    }
  }
}
</script>

<style>
.custom-file-input:lang(es) ~ .custom-file-label::after {
  content: 'Explorar';
}
</style>
